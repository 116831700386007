<template>
  <div class="vx-row">
    <div class="vx-col sm:w-full md:w-1/2 mb-base">
      <vx-card :title="($route.params.cost_id ? 'Edit' : 'Add') + ' Basic Cost'">
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <browse-cost class="w-full" @on-change="costChanged" :isvendor="true" v-validate="'required'" name="cost_id" label="Cost Name" v-model="cost_id"></browse-cost>
            <span class="text-danger text-sm" v-show="errors.has('cost_id')">{{errors.first('cost_id')}}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <browse-vendor class="w-full" :isvendor="true" v-validate="'required'" name="vendor_id" label="Vendor" v-model="vendor_id"></browse-vendor>
            <span class="text-danger text-sm" v-show="errors.has('vendor_id')">{{errors.first('vendor_id')}}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input type="number" @focus="$event.target.select()" @keyup="total_price = qty * price" class="w-full" label="Qty" v-validate="'required'" name="qty" v-model="qty"></vs-input>
            <span class="text-danger text-sm" v-show="errors.has('qty')">{{errors.first('qty')}}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input type="number" @focus="$event.target.select()" @keyup="total_price = qty * price" class="w-full" label="Cost" v-validate="'required'" name="price" v-model="price"></vs-input>
            <span class="text-danger text-sm" v-show="errors.has('price')">{{errors.first('price')}}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input type="number" readonly class="w-full" label="Total Cost" v-validate="'required'" name="total_price" v-model="total_price"></vs-input>
            <span class="text-danger text-sm" v-show="errors.has('total_price')">{{errors.first('total_price')}}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input class="w-full" label="Description" v-model="description"></vs-input>
          </div>
        </div>
        <vs-divider></vs-divider>
        <div class="flex">
          <slot name="footer"></slot>
          <vs-button class="ml-3" @click="store">Save</vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import BrowseVendor from '@browse/Contact.vue'
import BrowseCost from '@browse/Cost.vue'
export default {
  props: ['price_list_id'],
  components:{
    BrowseVendor,
    BrowseCost
  },
  data(){
    return {
      cost_id: null,
      vendor_id: null,
      qty: 0,
      price: 0,
      total_price: 0,
      description: "",
    }
  },
  mounted() {
     this.show()
  },
  methods:{
    async show() {
        if(this.$route.params.cost_id) {
            let {data} = await this.$store.dispatch('price_lists/showCost', this.$route.params.cost_id)
            this.cost_id = data.cost_id        
            this.description = data.description
            setTimeout(() => {
                this.vendor_id = data.vendor_id        
                this.qty = data.qty        
                this.price = data.price        
                this.total_price = data.total_price        
            }, 500)
        }
    },
    costChanged(data){
      if(data){
        this.vendor_id = data.vendor_id
        if(data.is_bbm) {
            this.qty = data.qty
        } else {
            this.qty = 1
        }
        this.price = data.total_price
        this.total_price = this.price * this.qty
      }
    },
    store(){
      this.$validator.validateAll().then(async res => {
        if (!res) return false;
        let params = {
          header_id: this.price_list_id,
          cost_id: this.cost_id,
          vendor_id: this.vendor_id,
          qty: this.qty,
          price: this.price,
          total_price: this.total_price,
          description: this.description 
        }
        if(this.$route.params.cost_id) {
            params.id = this.$route.params.cost_id
        }
        try {
          let method
          if(this.$route.params.cost_id) {
            method = 'price_lists/update_costs'
          } else {
            method = 'price_lists/store_costs'
          }
          let {data} = await this.$store.dispatch(method, params)
          this.$emit('on-success', data)
          this.$vs.notify({
            title: `Success!`,
            text: `Data was saved successfully`,
            color: `success`
          })
        } catch (error) {
          this.$vs.notify({
            title: `Oops!`,
            text: `Something went wrong!`,
            color: `danger`
          })
        }
      })
    }
  }
}
</script>

<style>

</style>